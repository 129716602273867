import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "./myslider.css";
import { Modal } from "@mantine/core";
import useOrderStore from "../../store/OrderStore";
import { Attachment } from "../../models/List/OrdersExecution";

const FullSlider = () => {
  const {
    isModalOpen,
    closeModal,
    orderExecutions,
    currentPhotos,
    photoIndex,
  } = useOrderStore();

  const beforeStartIndex =
    currentPhotos?.slice(0, photoIndex || undefined) ?? [];
  const afterStartIndex = currentPhotos?.slice(photoIndex || undefined) ?? [];
  const image = currentPhotos?.map((photo) => ({
    original: photo.original,
    thumbnail: photo.thumbnail,
  }));
  const images = [...afterStartIndex, ...beforeStartIndex].map((photo) => ({
    original: photo.original,
    thumbnail: photo.thumbnail,
  }));
  const finalImg = photoIndex && photoIndex > 0 ? images : image;

  return (
    <div>
      <Modal
        opened={isModalOpen}
        onClose={closeModal}
        size="100vw"
        className="z-350"
        centered
      >
        {finalImg && <ImageGallery items={finalImg} />}
      </Modal>
    </div>
  );
};

export default FullSlider;
