import React, { useState } from "react";

import "react-image-gallery/styles/css/image-gallery.css";
import "./myslider.css";

import useOrderStore from "../../store/OrderStore";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Tooltip } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import "@mantine/carousel/styles.css";
import { useMediaQuery } from "@mantine/hooks";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
export interface MySliderProps {
  content: {
    thumbnail?: string;
    original?: string;
    text: string;
    link?: string;
  }[];
  type: "photo" | "doc";
}

const MySlider: React.FC<MySliderProps> = ({ content, type, ...props }) => {
  const [isSwiping, setIsSwiping] = useState(false);
  const { openModal, setCurrentPhotos, setPhotoIndex } = useOrderStore();
  const openAllPhotos = (clickedItem: any, index: number) => {
    setCurrentPhotos(clickedItem);
    setPhotoIndex(index);
    openModal();
  };

  const isMobile = useMediaQuery("(max-width: 560px)");
  const contentLenght = content.length;
  return (
    <>
      {type === "doc" ? (
        <>
          <div className="slider-doc">
            {content.map((item, index) => (
              <div key={index} className="doc-wrapper">
                <a target="_blank" href={item.link}>
                  <Tooltip label="Открыть">
                    <img src={item.thumbnail} alt={`Image ${item.text}`} />
                  </Tooltip>
                </a>
                <a target="_blank" href={item.link}>
                  {item.text.length > 25 ? (
                    <Tooltip label={item.text}>
                      <p className="flex justify-around doc-name">
                        {item.text}
                      </p>
                    </Tooltip>
                  ) : (
                    <p className="flex justify-around doc-name">{item.text}</p>
                  )}
                </a>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="image-wrapper">
          <Carousel
            withControls={contentLenght > 3}
            height={isMobile ? 100 : 250}
            slideSize={contentLenght > 2 ? "33.33333%" : "50%"}
            slideGap={isMobile ? 0 : "md"}
            align={contentLenght > 3 ? "start" : "center"}
            loop
            slidesToScroll={3}
          >
            {content.map((item, index) => (
              <Carousel.Slide key={index}>
                <LazyLoadImage
                  alt={`Image ${item.text}`}
                  effect="blur"
                  height={"100%"}
                  src={item.thumbnail}
                  onClick={(e) => {
                    e.preventDefault();
                    if (!isSwiping && type === "photo") {
                      openModal();
                    }
                    openAllPhotos(content, index);
                  }}
                  style={{
                    objectFit: contentLenght < 3 ? "contain" : "cover",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default MySlider;
