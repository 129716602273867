import React, { useEffect, useState, useRef, useCallback } from "react";
import { useChatStore } from "../model/model";
import { Message } from "../api/model";
import { Badge, ScrollArea } from "@mantine/core";
import { format, isToday, isYesterday, isThisYear } from "date-fns";
import { ru } from "date-fns/locale"; // Импортируем русскую локаль

import style from "./ChatComponent.module.scss";
import FullSlider from "components/Slider/FullSlider";
import useOrderStore from "store/OrderStore";
import ChatMessage from "../../ChatMessage/ChatMessage";

interface Props {
  chatId: string;
  messages: Message[];
}

const ChatComponent: React.FC<Props> = ({ chatId, messages }) => {
  const { getChatsMessage, chatsMessage } = useChatStore();

  const [page, setPage] = useState(0);
  const size = 20;
  const pageLimit = (): number | undefined => {
    if (chatsMessage?.totalCount) {
      return Math.ceil(chatsMessage.totalCount / size); // округляем в большую сторону
    }
    return 2;
  };
  const [badgeText, setBadgeText] = useState<string>("");

  const [allMessages, setAllMessages] = useState<Message[]>([]);
  const [visibleMessages, setVisibleMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const {
    isModalOpen,
    closeModal,
    setCurrentPhotos,
    currentPhotos,
    setPhotoIndex,
    openModal,
  } = useOrderStore();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const viewport = useRef<HTMLDivElement>(null);
  const messageRefs = useRef<Map<string, HTMLElement>>(new Map());
  const observerRef = useRef<IntersectionObserver | null>(null);

  // Таймер для проверки времени нахождения вверху
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const SCROLL_DELAY = 2000; // Время в миллисекундах

  const handleClickImg = (id: string) => {
    // Filter messages to get images
    const imageMessages = allMessages.filter((message) =>
      message.attachments.some(
        (attachment) => attachment.type && attachment.type.startsWith("image/")
      )
    );

    // Filter images from the messages
    const images = filterImages(imageMessages);

    // Create transformed images array with 'id' included
    const transformedImages = images.map((image) => ({
      id: image.id, // Ensure 'id' is included
      original: image.original,
      thumbnail: image.thumbnail,
      text: "", // Add a text property as needed
    }));

    // Update state with transformed images
    setCurrentPhotos(transformedImages);

    // Find the index of the image to be displayed
    const photoIndex = transformedImages.findIndex(
      (image) =>
        image.original === images.find((img) => img.id === id)?.original
    );

    // Set the photo index
    setPhotoIndex(photoIndex);

    // Open the modal to display the image
    openModal();
  };

  // Сохранение позиции прокрутки перед загрузкой новых сообщений

  // Загрузка сообщений при монтировании компонента или изменении chatId
  useEffect(() => {
    loadMessages(0); // Загрузка первой страницы сообщений
  }, [chatId]);

  // Фильтрация изображений из сообщений
  const filterImages = (messages: Message[]) => {
    return messages.flatMap((message) =>
      message.attachments
        .filter(
          (attachment) =>
            attachment.type && attachment.type.startsWith("image/")
        )
        .map((attachment) => ({
          id: attachment.id,
          original: `${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${attachment.id}/view/?format=preview`,
          thumbnail: `${process.env.REACT_APP_BACK_ADDRESS}/file-storage/${attachment.id}/view/?format=thumbnail`,
          text: "", // Add a text property
        }))
    );
  };

  // Обновление currentPhotos при изменении сообщений
  useEffect(() => {
    const images = filterImages(allMessages);
    setCurrentPhotos(images);
  }, [allMessages]);
  const loadMoreMessages = () => {
    if (viewport.current) {
      const scrollHeightBeforeLoad = viewport.current.scrollHeight;
      const scrollTopBeforeLoad = viewport.current.scrollTop;

      const limit = pageLimit();
      if (limit !== undefined && limit > page) {
        setPage((prevPage) => {
          const newPage = prevPage + 1;

          getChatsMessage(chatId, { size: 20, page: newPage }).then(
            (newMessages) => {
              // Создаем временный контейнер для измерений
              const tempContainer = document.createElement("div");
              tempContainer.style.position = "absolute";
              tempContainer.style.visibility = "hidden";
              tempContainer.style.overflow = "hidden";
              document.body.appendChild(tempContainer);

              newMessages.items.forEach((message) => {
                // Создаем элемент для каждого нового сообщения
                const messageElement = document.createElement("div");
                messageElement.innerHTML = `
                  <div class="${style.message}">
                    ${message.content}
                  </div>
                `;
                tempContainer.appendChild(messageElement);
              });

              // Измеряем высоту временного контейнера
              const newMessagesHeight = tempContainer.scrollHeight;
              console.log("newMessagesHeight", newMessagesHeight);

              // Удаляем временный контейнер
              document.body.removeChild(tempContainer);

              setAllMessages((prevMessages) => [
                ...newMessages.items.reverse(),
                ...prevMessages,
              ]);

              requestAnimationFrame(() => {
                if (viewport.current) {
                  const newScrollHeight = viewport.current.scrollHeight;
                  const scrollTopAdjustment =
                    newScrollHeight - scrollHeightBeforeLoad;

                  // Применяем смещение скролла
                  viewport.current.scrollTop =
                    scrollTopBeforeLoad + scrollTopAdjustment;

                  // Проверяем снова после загрузки изображений
                  requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                      if (viewport.current) {
                        const images =
                          viewport.current.getElementsByTagName("img");
                        const imageLoadPromises = Array.from(images).map(
                          (img) =>
                            new Promise((resolve) => {
                              if (img.complete) {
                                resolve(true);
                              } else {
                                img.onload = () => resolve(true);
                                img.onerror = () => resolve(true);
                              }
                            })
                        );

                        Promise.all(imageLoadPromises).then(() => {
                          if (viewport.current) {
                            const finalScrollHeight =
                              viewport.current.scrollHeight;
                            const finalScrollTopAdjustment =
                              finalScrollHeight - scrollHeightBeforeLoad;
                            console.log(
                              "finalScrollTopAdjustment",
                              finalScrollTopAdjustment
                            );

                            viewport.current.scrollTop =
                              scrollTopBeforeLoad + finalScrollTopAdjustment;
                          }
                        });
                      }
                    });
                  });
                }
              });
            }
          );

          return newPage;
        });
      }
    }
  };

  useEffect(() => {
    console.log("viewport.current.scrollTop1", viewport?.current?.scrollTop);
  }, [viewport?.current?.scrollTop]);

  // Обработка скролла для автоматической загрузки дополнительных сообщений
  const handleScroll = () => {
    if (viewport.current) {
      if (viewport.current.scrollTop <= 10) {
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }

        scrollTimeoutRef.current = setTimeout(() => {
          if (
            viewport.current &&
            viewport.current.scrollTop <= 10 &&
            !loading
          ) {
            setLoading(true);
            loadMoreMessages();
            setLoading(false);
          }
        }, SCROLL_DELAY);
      }
    }
  };

  console.log("visibleMessages", visibleMessages);

  // Добавление обработчика скролла при монтировании компонента
  useEffect(() => {
    if (viewport.current) {
      viewport.current.addEventListener("scroll", handleScroll);
      return () => {
        if (viewport.current) {
          viewport.current.removeEventListener("scroll", handleScroll);
        }
        // Очистка таймера при размонтировании
        if (scrollTimeoutRef.current) {
          clearTimeout(scrollTimeoutRef.current);
        }
      };
    }
  }, [chatContainerRef]);

  // Функция определения, является ли сообщение последним в группе
  const isLastInGroup = (currentIndex: number): boolean => {
    if (currentIndex === allMessages.length - 1) {
      return true;
    }
    const currentMessage = allMessages[currentIndex];
    const nextMessage = allMessages[currentIndex + 1];
    return nextMessage && currentMessage.iamSender === nextMessage.iamSender
      ? false
      : true;
  };

  // Функция для определения текста бейджа с датой
  const getDateBadgeText = (dates: Date[]) => {
    const sortedDates = dates.sort((a, b) => b.getTime() - a.getTime());

    if (sortedDates.some((date) => isToday(date))) {
      return "Сегодня";
    }

    if (sortedDates.some((date) => isYesterday(date))) {
      return "Вчера";
    }

    const thisYearDates = sortedDates.filter((date) => isThisYear(date));
    if (thisYearDates.length > 0) {
      return format(thisYearDates[0], "d MMMM", { locale: ru }); // Форматирование даты с русской локалью
    }

    return format(sortedDates[0], "d MMMM yyyy", { locale: ru }); // Форматирование даты с русской локалью
  };

  // Обработчик видимых сообщений с помощью IntersectionObserver
  const handleVisibleMessages = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const visibleMessageIds = entries
        .filter((entry) => entry.intersectionRatio > 0)
        .map((entry) => entry.target.getAttribute("data-id"))
        .filter((id) => id !== null) as string[];

      const uniqueVisibleMessageIds = Array.from(new Set(visibleMessageIds));
      const visibleMessages = allMessages.filter((message) =>
        uniqueVisibleMessageIds.includes(message.id)
      );

      setVisibleMessages(visibleMessages);

      // Обновите текст бейджа
      if (visibleMessages.length > 0) {
        const dates = visibleMessages.map(
          (message) => new Date(message.timestamp)
        );
        setBadgeText(getDateBadgeText(dates));
      } else {
        // Если нет видимых сообщений, используем предыдущее значение бейджа
        setBadgeText((prevBadgeText) => prevBadgeText);
      }
    },
    [allMessages]
  );

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const messageId = entry.target.getAttribute("data-id");
        if (messageId) {
          // Проверяем, видимо ли сообщение после изменения размера
          checkMessageVisibility(messageId);
        }
      }
    });

    messageRefs.current.forEach((element) => {
      resizeObserver.observe(element);
    });

    return () => {
      resizeObserver.disconnect();
    };
  }, [allMessages]);

  const checkMessageVisibility = (messageId: string) => {
    const element = messageRefs.current.get(messageId);
    if (element && viewport.current) {
      const rect = element.getBoundingClientRect();
      const viewportRect = viewport.current.getBoundingClientRect();
      if (rect.top < viewportRect.bottom && rect.bottom > viewportRect.top) {
        setVisibleMessages((prev) =>
          prev.some((msg) => msg.id === messageId)
            ? prev
            : [...prev, allMessages.find((msg) => msg.id === messageId)!]
        );
      }
    }
  };

  // Инициализация IntersectionObserver при монтировании компонента
  useEffect(() => {
    const observer = new IntersectionObserver(handleVisibleMessages, {
      root: viewport.current,
      rootMargin: "0px ",
      threshold: [0],
    });

    observerRef.current = observer;

    return () => {
      observer.disconnect();
    };
  }, [handleVisibleMessages]);

  // Обновление наблюдения при изменении сообщений
  useEffect(() => {
    if (observerRef.current) {
      const observer = observerRef.current;
      messageRefs.current.forEach((element) => {
        if (element) {
          observer.observe(element);
        }
      });
    }
  }, [allMessages]);

  // Загрузка сообщений с заданной страницей
  const loadMessages = (pageNum: number) => {
    getChatsMessage(chatId, { size: 20, page: pageNum }).then((newMessages) => {
      setAllMessages((prevMessages) => [
        ...prevMessages,
        ...newMessages.items
          .reverse()
          .filter(
            (msg) => !prevMessages.some((prevMsg) => prevMsg.id === msg.id)
          ),
      ]);
    });
  };

  // Добавляем сообщения при их изменении
  useEffect(() => {
    if (messages.length > 0) {
      setAllMessages((prevMessages) => [
        ...prevMessages,
        ...messages.filter(
          (msg) => !prevMessages.some((prevMsg) => prevMsg.id === msg.id)
        ),
      ]);
    }
  }, [messages]);

  // Прокрутка до конца при обновлении сообщений (исправленная)
  useEffect(() => {
    if (viewport.current) {
      const images = viewport.current.getElementsByTagName("img");
      const imageLoadPromises = Array.from(images).map(
        (img) =>
          new Promise((resolve) => {
            if (img.complete) {
              resolve(true);
            } else {
              img.onload = () => resolve(true);
              img.onerror = () => resolve(true);
            }
          })
      );

      Promise.all(imageLoadPromises).then(() => {
        viewport.current?.scrollTo({
          top: viewport.current.scrollHeight,
          behavior: "auto",
        });
      });
    }
  }, [allMessages[allMessages.length - 1]]);

  const formatDate = (timestamp: string): string => {
    // Создаем объект Date из строки
    const date = new Date(timestamp);

    // Форматируем дату в нужный формат
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Месяцы в JS начинаются с 0
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  return (
    <ScrollArea
      offsetScrollbars
      style={{ height: "100%", width: "100%" }}
      scrollbarSize={6}
      scrollbars="y"
      viewportRef={viewport}
    >
      {badgeText && (
        <Badge
          variant="light"
          color="gray"
          style={{
            position: "absolute",
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1,
          }}
        >
          {badgeText}
        </Badge>
      )}
      <div className={style.messageList}>
        {allMessages.map((message, index) => (
          <div
            key={`${message.id}-${index}`} // Исправление ключа для сообщений
            ref={(el) => {
              if (el) {
                messageRefs.current.set(message.id, el);
                if (observerRef.current) {
                  observerRef.current.observe(el);
                }
              }
            }}
            data-id={message.id}
          >
            <>
              {message.attachments.map((attachment) => (
                <ChatMessage
                  key={attachment.id}
                  message={message.content}
                  author={message.sender.username}
                  time={formatDate(message.timestamp)}
                  amIAuthor={message.iamSender}
                  isLastMessage={false}
                  isFile={true}
                  attachment={attachment}
                  onClickImg={() => handleClickImg(attachment.id)}
                />
              ))}
              {message.content !== "" && (
                <ChatMessage
                  message={message.content}
                  author={message.sender.username}
                  time={formatDate(message.timestamp)}
                  amIAuthor={message.iamSender}
                  isLastMessage={isLastInGroup(index)}
                  isFile={false}
                />
              )}
            </>
          </div>
        ))}
      </div>
      <FullSlider />
    </ScrollArea>
  );
};

export default ChatComponent;
